import React from "react";
import "./Footer.css"; // Optional: Use CSS to style your footer

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <p>© {currentYear} Eric Kurachi. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
