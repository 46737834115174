import React from 'react';
import PortfolioHeader from './PortfolioHeader';
import SocialMediaBar from './SocialMediaBar';
import Footer from "./Footer";

const App = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <PortfolioHeader />
      {/* Other components/content of portfolio */}
      <SocialMediaBar />
      <Footer />
    </div>
  );
};

export default App;